export const DEFAULT_FILTERS_STATE = {
  status: [],
  merchantId: null,
  userId: null,
  payoutMethodTypeId: null,
  merchantTransactionId: null,
  merchantUserId: null,
  operatorTransactionId: null,
  currency: null,
  customerWalletId: '',
  customerName: null,
  payload: null,
  agent: null,
  createdAt: {
    from: '',
    to: '',
  },
};
