export const reasonTypes = {
    UserAccountLimitExceeded: "User account limit exceeded",
    InvalidClientDetails: "Invalid client details",
    NotEnoughData: "Not enough data to complete the payment",
    AccountOwnerNameNotMatch: "Account owner name does not match",
    TransactionDeclinedInBank: "Transaction declined on the bank's side",
    AgentIsNotReadyToAccept: "The agent is not ready to accept payments",
    UserAccountNotAcceptingPayment: "User account not accepting the payment",
    FailedWhileProcessing: "Failed while processing",
    AnotherReason: "Another reason",
};
export const AllReasonTypes = Object.keys(reasonTypes);