<template>
  <q-dialog ref="dialog" @hide="onDialogHide" persistent>
    <q-card class="q-dialog-plugin">
      <q-form ref="form" @submit="onOKClick">
        <q-card-section class="text-h6"> Decline order </q-card-section>
        <q-card-section>
          <div class="text-subtitle2">Select the reason for declined of the order</div>
          <q-select
            dense
            v-model="reasonType"
            outlined
            :options="reasonsOptions"
            class="q-mb-sm"
            :rules="[testNotEmpty]"
          />
          <q-input
              v-if="showText"
              dense
              v-model="reasonText"
              outlined
              type="textarea"
              :rules="[testNotEmpty]"
              placeholder="Enter reason"
          />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn color="primary" label="OK" type="submit" />
          <q-btn color="primary" label="Cancel" @click="onCancelClick" />
        </q-card-actions>
      </q-form>
    </q-card>
  </q-dialog>
</template>

<script>

import {AllReasonTypes, reasonTypes} from "@/features/decline-reason-dialog/config";

export default {
  data: () => ({
    reasonType: null,
    reasonText: null,
  }),

  computed: {
    reasonsOptions: () => AllReasonTypes.map(key => ({
      value: key,
      label: reasonTypes[key]
    })),

    showText() {
      return !!this.reasonType && this.reasonType.label === reasonTypes.AnotherReason;
    },
  },

  methods: {
    show() {
      this.$refs.dialog.show();
    },

    hide() {
      this.$refs.dialog.hide();
    },

    onDialogHide() {
      this.$emit('hide');
    },

    onOKClick() {
      this.$emit('ok', { type: this.reasonType.value, text: this.reasonText });
      this.hide();
    },

    onCancelClick() {
      this.hide();
    },

    testNotEmpty: (str) => !!str || 'Value is empty!',
  },
};
</script>
