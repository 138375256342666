import { render, staticRenderFns } from "./grid-card.vue?vue&type=template&id=8b63e150&"
import script from "./grid-card.vue?vue&type=script&lang=js&"
export * from "./grid-card.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QCardSection,QSelect,QChip,QSlideTransition,QInput,QBtn});
