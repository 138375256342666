<template>
  <d-view-card
    ref="view"
    :fetch-function="payoutOrderController.getOrders"
    :filters="filters"
    :pagination="pagination"
    :filter-transformer="clearFilters"
    readonly-data
  >
    <template
      #default="{
        loading,
        find,
        selectedData,
        removeHandler,
        data,
        filtersEntity,
      }"
    >
      <div class="col-shrink row q-px-md q-pt-md">
        <q-card class="full-width">
          <q-card-section class="row col q-col-gutter-sm">
            <q-select
              class="col-md-3 col-6"
              filled
              label="Status"
              clearable
              dense
              multiple
              use-chips
              emit-value
              map-options
              v-model="filtersEntity.status"
              :options="statusDictionary"
            />
            <q-select
              class="col-md-3 col-6"
              emit-value
              map-options
              dense
              filled
              label="Payout method"
              option-label="name"
              option-value="id"
              :options="payoutMethodTypesDictionary"
              v-model="filtersEntity.payoutMethodTypeId"
            />
            <q-input
              class="col-md-3 col-6"
              label="Merchant transaction Id"
              dense
              filled
              v-model="filtersEntity.merchantTransactionId"
            />
            <q-input
              class="col-md-3 col-6"
              label="Operator transaction Id"
              dense
              filled
              v-model="filtersEntity.operatorTransactionId"
            />
            <q-select
              v-if="isSupportOfficer"
              class="col-md-2 col-6"
              label="Currency"
              dense
              filled
              clearable
              emit-value
              map-options
              option-value="code"
              option-label="code"
              :options="currencies"
              v-model="filtersEntity.currency"
            />
            <q-input
              v-else
              class="col-md-2 col-6"
              label="Currency"
              dense
              filled
              clearable
              v-model="filtersEntity.currency"
            />
            <q-input
              class="col-md-3 col-6"
              label="Customer Wallet Id"
              dense
              filled
              v-model="filtersEntity.customerWalletId"
            />
            <q-input
              class="col-md-3 col-6"
              label="Customer name"
              dense
              filled
              v-model="filtersEntity.customerName"
            />
            <d-user-select
              v-if="!isOperator"
              label="Operator"
              class="col-md-3 col-6"
              dense
              filled
              hide-bottom-space
              hide-hint
              v-model="filtersEntity.userId"
              emit-value
              map-options
              :options="specialOperators"
            />
            <q-input
              class="col-md-3 col-6"
              label="Merchant user ID"
              dense
              filled
              v-model="filtersEntity.merchantUserId"
            />
            <d-user-select
              label="Agent"
              class="col-md-3 col-6"
              dense
              filled
              hide-bottom-space
              hide-hint
              v-model="filtersEntity.agent"
              emit-value
              map-options
              v-if="!isAgent"
              :options="agentDictionary"
            />
            <d-date-range
              ref="dateRange"
              class="col-md-6 col-12"
              :value="[
                filtersEntity.createdAt.from,
                filtersEntity.createdAt.to,
              ]"
              @input="setDateRange"
            />
            <q-input
              class="col"
              label="Payload"
              dense
              filled
              v-model="filtersEntity.payload"
            />
          </q-card-section>
          <q-card-actions align="right">
            <q-btn
              v-if="!isSupportOfficer"
              outline
              color="primary"
              label="Report"
              @click="onReportHandler"
            />
            <q-btn color="primary" label="Apply" @click="find" />
          </q-card-actions>
        </q-card>
      </div>
      <div class="col-grow">
        <div class="q-pa-md">
          <d-payout-orders-table
            :grid="$q.screen.lt.md"
            :items="data"
            :chip-status-config-builder="getConfigAtStatusValue"
            @on-pagination="onPaginationHandler"
            @on-status="setStatusHandler"
          />
        </div>
      </div>
    </template>
  </d-view-card>
</template>

<script>
import { DEFAULT_FILTERS_STATE } from './config';
import { DDateRange } from '@/features/date-range-picker';
import { DDeclineReasonDialog } from '@/features/decline-reason-dialog';
import { reasonTypes } from '@/features/decline-reason-dialog/config';
import { DPayoutOrdersTable } from '@/features/payout-orders-table';
import DUserSelect from '@/features/user-select';
import DViewCard from '@/layouts/view-card';
import {
  payoutOrderController as apiController,
  payoutMethodController,
} from '@/shared/api';
import { usersController } from '@/shared/api';
import {
  ROLES,
  ORDER_STATUS_DICTIONARY as statusDictionary,
} from '@/shared/constants';
import { default as refillAndPayoutOrdersMixin } from '@/shared/mixins/refillAndPayoutOrders';
import withReportLoader from '@/shared/mixins/withReportLoader';
import withStatuses from '@/shared/mixins/withStatuses';
import { authUtils, notify } from '@/shared/utils';
import { mapGetters } from 'vuex';

export default {
  components: { DPayoutOrdersTable, DViewCard, DDateRange, DUserSelect },
  mixins: [
    withStatuses,
    withReportLoader,
    refillAndPayoutOrdersMixin({
      apiController,
    }),
  ],
  data: () => ({
    filters: { ...DEFAULT_FILTERS_STATE },
    pagination: {
      lastId: 0,
      maxResults: 512,
      descending: true,
    },
    statusDictionary,
    payoutOrderController: apiController(authUtils.getRoleByHierarchy()),
    payoutMethodTypesDictionary: [],
    agentDictionary: [],
  }),

  async mounted() {
    const isAgent = authUtils.getRoleByHierarchy() === ROLES.ROLE_AGENT;

    const [userResponse] = await Promise.all([
      isAgent
        ? Promise.resolve()
        : usersController(authUtils.getRoleByHierarchy()).agentsForFilter(),
      this.fetchPayoutMethodTypes(),
    ]);

    this.agentDictionary = userResponse ? Object.freeze(userResponse.data) : [];
  },
  computed: {
    ...mapGetters({
      currencies: 'GET_USER_CURRENCIES',
    }),
    isSupportOfficer: () =>
      authUtils.getRoleByHierarchy() === ROLES.ROLE_SUPPORT_OFFICER,
    isAgent: () => authUtils.getRoleByHierarchy() === ROLES.ROLE_AGENT,
  },
  methods: {
    setDateRange(value) {
      this.filters.createdAt.from = value[0];
      this.filters.createdAt.to = value[1];
    },

    async setStatusHandler(event, id) {
      if (event.value === 1) {
        this.$q
          .dialog({
            title: 'Approve payout orders',
            message: 'Enter operator transaction Id',
            prompt: {
              model: '',
              type: 'text',
              isValid: (val) => val.trim().length > 0,
            },
            cancel: true,
            persistent: true,
          })
          .onOk((operatorTransactionId) =>
            this.setOrderStatus(event, { id, operatorTransactionId })
          );
      } else {
        this.$q
          .dialog({
            component: DDeclineReasonDialog,
            parent: this,
          })
          .onOk(({ type, text }) => {
            const reasonDecline = text || reasonTypes[type];
            return this.setOrderStatus(event, { id, reasonDecline });
          });
      }
    },

    onReportHandler() {
      const isValid = this.$refs.dateRange.validate();
      if (!isValid) return;

      this.callPromptForEmail().onOk(async (email) => {
        try {
          await this.payoutOrderController.applyReport({
            filter: this.clearFilters(this.filters),
            email,
          });
          notify.success('Report request sent successfully');
        } catch (e) {
          notify.error('Something went wrong while sending a report request.');
        }
      });
    },

    async fetchPayoutMethodTypes() {
      const { data } = await payoutMethodController(
        authUtils.getRoleByHierarchy()
      ).getPayoutMethods();

      this.payoutMethodTypesDictionary = Object.freeze(data);
    },
  },
};
</script>
