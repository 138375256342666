import { formatAmount } from '@/shared/utils';

export const tableColumns = [
  {
    name: 'details',
    field: 'details',
    label: 'Details',
    isHide: false,
    align: 'left',
  },
  {
    name: 'merchant-transaction-id',
    field: 'merchantTransactionId',
    label: 'Merchant transaction Id',
    isHide: false,
  },
  {
    name: 'operator-transaction-id',
    field: 'operatorTransactionId',
    label: 'Operator transaction id',
    isHide: false,
  },
  {
    name: 'amount',
    field: 'amount',
    label: 'Amount',
    format: (val) => formatAmount(Math.trunc(+val)),
    isHide: false,
  },
  {
    name: 'status',
    field: 'status',
    label: 'Status',
    isHide: false,
  },
  {
    name: 'username',
    field: (row) => row?.agent?.username,
    label: 'Username',
    isHide: false,
  },
  {
    name: 'merchant',
    field: (row) => row.merchant.name,
    label: 'Merchant name',
    isHide: true,
  },
  {
    name: 'payout-method-type-name',
    field: (row) => row.payoutMethodType.name,
    label: 'Payout Method Type',
    isHide: true,
  },
  {
    name: 'currency',
    field: 'currency',
    label: 'Currency',
    isHide: true,
  },
  {
    name: 'operator',
    field: (row) => row?.user?.username,
    label: 'Operator',
    isHide: true,
  },
  {
    name: 'customer-name',
    field: 'customerName',
    label: 'Customer name',
    isHide: true,
  },
  {
    name: 'payload',
    field: 'payload',
    label: 'Payload',
    isHide: true,
  },
  {
    name: 'customer-wallet-id',
    field: 'customerWalletId',
    label: 'Customer wallet id',
    isHide: true,
  },
  {
    name: 'merchant-user-id',
    field: 'merchantUserId',
    label: 'Merchant user ID',
    isHide: true,
  },
  {
    name: 'created-at',
    field: (row) => row?.createdAt,
    label: 'Created at',
    isHide: true,
  },
  {
    name: 'updatedAt',
    field: (row) => row?.updatedAt,
    label: 'Updated at',
    isHide: true,
  },
];
