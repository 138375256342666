import { usersController } from '@/shared/api';
import { ROLES } from '@/shared/constants';
import { authUtils, notify, objectUtils } from '@/shared/utils';

export default ({ apiController }) => ({
  data: () => ({
    pagination: {
      lastId: 0,
      maxResults: 512,
      descending: false,
    },
    specialOperators: [],
  }),

  async mounted() {
    try {
      if (this.isOperator) return;
      const { data } = await usersController(
        authUtils.getRoleByHierarchy()
      ).usersForFilter();
      this.specialOperators = data;
    } catch (e) {
      notify.error(e.message);
    }
  },

  computed: {
    isOperator: () => authUtils.getRoleByHierarchy() === ROLES.ROLE_OPERATOR,
    isOperatorOrAgent: () =>
      [ROLES.ROLE_OPERATOR, ROLES.ROLE_AGENT].includes(
        authUtils.getRoleByHierarchy()
      ),
  },

  methods: {
    async onPaginationHandler({ rowsPerPage }) {
      this.pagination.maxResults = rowsPerPage;
      await this.$refs.view.find();
    },
    async setOrderStatus({ value }, data) {
      try {
        this.$refs.view.setLoading(true);
        if (value === 1) {
          await apiController(authUtils.getRoleByHierarchy()).approveOrder(
            data
          );
        } else {
          await apiController(authUtils.getRoleByHierarchy()).declineOrder(
            data
          );
        }
        await this.$refs.view.find();
      } catch (e) {
        notify.error(e.message);
        console.error(e);
      } finally {
        this.$refs.view.setLoading(false);
      }
    },
    clearFilters(filters) {
      let result = objectUtils.removeEmptyValuesRecursively(filters);
      if (filters.status?.length === 1 && filters.status?.[0] === 0) {
        result = {
          ...result,
          status: [0],
        };
      }

      return result;
    },
  },
});
