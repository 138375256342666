<template>
  <div>
    <label class="label text-caption">{{ label }}</label>
    <template v-for="(val, key) in value">
      <div :key="key">
        <span class="text-weight-bold q-mr-xs"> {{ key }}: </span>
        <template v-if="isValidUrl(val)">
          <a :href="val" target="_blank">{{ val }}</a>
        </template>
        <span v-else>
          {{ val }}
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import { isValidUrl } from '@/shared/utils';

export default {
  props: {
    label: {
      type: String,
      default: () => '',
    },
    value: {
      type: [Object, Array],
      default: () => [],
    },
  },
  methods: {
    isValidUrl,
  },
};
</script>

<style scoped lang="scss">
.label {
  color: rgba(0, 0, 0, 0.6);
}

a {
  text-decoration: none;
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
