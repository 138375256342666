<template>
  <div class="q-pa-xs col-xs-12 col-sm-6 col-md-4">
    <d-grid-card-wrapper>
      <q-card-section class="q-pb-none">
        <div class="grid-item__row">
          <div class="grid-item__title">Merchant transaction Id</div>
          <div class="grid-item__value">
            {{ item.row.merchantTransactionId }}
          </div>
        </div>
        <div class="grid-item__row">
          <div class="grid-item__title">Operator transaction Id</div>
          <div class="grid-item__value">
            {{ item.row.operatorTransactionId }}
          </div>
        </div>
        <div class="grid-item__row">
          <div class="grid-item__title">Amount</div>
          <div class="grid-item__value">
            {{ item.colsMap.amount.format(item.row.amount) }}
          </div>
        </div>
        <div class="grid-item__row">
          <div class="grid-item__title">Username</div>
          <div class="grid-item__value">
            {{ item.row.username || 'None' }}
          </div>
        </div>
        <div class="grid-item__row">
          <div class="grid-item__title">Status</div>
          <div class="grid-item__value">
            <q-select
              :disable="statusSelectDisable"
              dense
              borderless
              stack-label
              :value="item.row.status"
              @input="$emit('on-status', $event)"
              :options="statusDictionaryFn(item.row.status)"
            >
              <template v-slot:selected>
                <q-chip
                  dense
                  square
                  v-bind="statusChipProps"
                  class="q-my-xs q-ml-xs q-mr-none"
                />
              </template>
            </q-select>
          </div>
        </div>
      </q-card-section>
      <q-slide-transition>
        <div v-show="expanded">
          <q-card-section class="text-subitle2 q-pt-none">
            <div v-if="item.row.operator" class="grid-item__row">
              <div class="grid-item__title">Changed by</div>
              <div class="grid-item__value">
                {{ item.row.operator.username }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Merchant name</div>
              <div class="grid-item__value">
                {{ item.row.merchant.name }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Customer name</div>
              <div class="grid-item__value">
                {{ item.row.customerName }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Customer Wallet Id</div>
              <div class="grid-item__value">
                {{ item.row.customerWalletId }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Currency</div>
              <div class="grid-item__value">
                {{ item.row.currency }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Payout Method Type</div>
              <div class="grid-item__value">
                {{ item.row.payoutMethodType.name }}
              </div>
            </div>
            <div v-if="item.row.user" class="grid-item__row">
              <div class="grid-item__title">Operator</div>
              <div class="grid-item__value">
                {{ item.row.user.username }}
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Payload</div>
              <div class="grid-item__value">
                <template v-if="item.row.payload">
                  <div v-for="(val, key) in item.row.payload" :key="key">
                    <span class="text-weight-bold q-mr-xs"> {{ key }}: </span>
                    <span>
                      {{ val }}
                    </span>
                  </div>
                </template>
                <template v-else>
                  <div>None</div>
                </template>
              </div>
            </div>
            <div
              v-if="item.row.status === STATUSES.DECLINED"
              class="grid-item__row"
            >
              <div class="grid-item__title">Comment</div>
              <div class="grid-item__value">
                <q-input
                  readonly
                  outlined
                  :value="item.row.reasonDecline || 'None'"
                  type="textarea"
                  label="Comment"
                />
              </div>
            </div>
            <div class="grid-item__row">
              <div class="grid-item__title">Created at</div>
              <div class="grid-item__value">
                {{ item.row.createdAt }}
              </div>
            </div>
            <div v-if="item.row.updatedAt" class="grid-item__row">
              <div class="grid-item__title">Updated at</div>
              <div class="grid-item__value">
                {{ item.row.updatedAt }}
              </div>
            </div>
          </q-card-section>
        </div>
      </q-slide-transition>
      <q-btn
        flat
        :icon="expanded ? 'mdi-chevron-double-up' : 'mdi-chevron-double-down'"
        class="full-width"
        @click="expanded = !expanded"
      />
    </d-grid-card-wrapper>
  </div>
</template>

<script>
import { DGridCardWrapper } from '@/features/grid-card-wrapper';
import { STATUSES } from '@/shared/constants';

export default {
  computed: {
    STATUSES() {
      return STATUSES;
    },
  },
  components: {
    DGridCardWrapper,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    statusSelectDisable: {
      type: Boolean,
      default: () => false,
    },
    statusChipProps: {
      type: Object,
      required: true,
    },
    statusDictionaryFn: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    expanded: false,
  }),
};
</script>
