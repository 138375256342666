import {
  ORDER_STATUS_DICTIONARIES_MAP,
  ORDER_STATUS_DICTIONARY,
  ORDER_STATUS_DISABLING_MAP,
  ORDER_TYPES,
  ROLES,
} from '@/shared/constants';
import { authUtils } from '@/shared/utils';

export const withStatusDisabling = (type = ORDER_TYPES.REFILLS) => ({
  data: () => ({
    tmp: ORDER_STATUS_DICTIONARY,
  }),
  computed: {
    isAdmin: () => authUtils.getRoleByHierarchy() === ROLES.ROLE_ADMIN,
    isSupportOfficer: () =>
      authUtils.getRoleByHierarchy() === ROLES.ROLE_SUPPORT_OFFICER,
  },
  methods: {
    checkIsDisable(status) {
      const role = authUtils.getRoleByHierarchy();
      if (role in ORDER_STATUS_DISABLING_MAP[type]) {
        return ORDER_STATUS_DISABLING_MAP[type][role][status];
      }
      return true;
    },

    getStatusesByCurrentStatus(current) {
      const role = authUtils.getRoleByHierarchy();
      if (role in ORDER_STATUS_DICTIONARIES_MAP[type]) {
        return ORDER_STATUS_DICTIONARIES_MAP[type][role][current];
      }
      return [];
    },
  },
});
