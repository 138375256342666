<template>
  <q-table
    :grid="grid"
    :columns="columns"
    :data="items"
    :rows-per-page-options="[25, 50, 100]"
    with-pagination
    @on-pagination="$emit('on-pagination', $event)"
  >
    <template v-if="grid" #item="props">
      <d-grid-card
        :status-chip-props="chipStatusConfigBuilder(props.row.status)"
        :status-dictionary-fn="getStatusesByCurrentStatus"
        :item="props"
        :status-select-disable="checkIsDisable(props.row.status)"
        @on-status="$emit('on-status', $event, props.row.id)"
        @on-prompt="$emit('on-prompt', { id: props.row.id, amount: $event })"
      />
    </template>

    <template v-else #body="props">
      <q-tr :props="props">
        <template v-for="col in props.cols">
          <q-td
            v-if="col.name === 'details'"
            auto-width
            :props="props"
            :key="col.name"
          >
            <q-btn
              size="sm"
              color="accent"
              round
              dense
              unelevated
              @click="props.expand = !props.expand"
              :icon="!props.expand ? 'mdi-chevron-down' : 'mdi-chevron-up'"
            />
          </q-td>
          <q-td
            v-else-if="col.name === 'status'"
            :key="col.name"
            :props="props"
          >
            <q-select
              :disable="checkIsDisable(props.row.status)"
              dense
              outlined
              stack-label
              :value="props.row.status"
              @input="$emit('on-status', $event, props.row.id)"
              :options="getStatusesByCurrentStatus(props.row.status)"
            >
              <template v-slot:selected>
                <q-chip
                  dense
                  square
                  v-bind="chipStatusConfigBuilder(props.row.status)"
                  class="q-my-xs q-ml-xs q-mr-none"
                />
              </template>
            </q-select>
          </q-td>
          <q-td v-else :key="col.name" :props="props">
            {{ col.value }}
          </q-td>
        </template>
      </q-tr>

      <q-tr v-show="props.expand" :props="props">
        <q-td colspan="100%">
          <d-expanded-area v-if="props.expand" :value="props" />
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
import { tableColumns } from './config';
import { DExpandedArea, DGridCard } from './features';
import {
  ORDER_TYPES,
  ROLES,
  ORDER_STATUS_DICTIONARY as statusDictionary,
} from '@/shared/constants';
import { withStatusDisabling } from '@/shared/mixins/withStatusDisabling';
import { authUtils } from '@/shared/utils';

export default {
  components: { DGridCard, DExpandedArea },
  mixins: [withStatusDisabling(ORDER_TYPES.PAYOUTS)],
  props: {
    grid: {
      type: Boolean,
      default: () => false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    chipStatusConfigBuilder: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    statusDictionary,
  }),
  computed: {
    columns() {
      const filteredCols = authUtils.checkRoles([ROLES.ROLE_AGENT])
        ? tableColumns
        : tableColumns.filter((el) => el.name !== 'operator');

      return filteredCols.filter((col) => !col.isHide);
    },
  },
};
</script>

<style scoped lang="scss">
.virtual-scroll-table {
  height: 49em;
}
</style>
