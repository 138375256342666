<template>
  <div class="row q-gutter-sm">
    <div class="col-3 column q-gutter-sm">
      <q-input
        outlined
        class="col-shrink"
        readonly
        dense
        label="Merchant name"
        :value="value.row.merchant.name"
      />
      <q-input
        outlined
        class="col-shrink"
        readonly
        dense
        label="Customer name"
        :value="value.row.customerName"
      />
      <q-input
        outlined
        class="col-shrink"
        readonly
        dense
        label="Customer Wallet Id"
        :value="value.row.customerWalletId"
      />
      <q-input
        outlined
        class="col-shrink"
        readonly
        dense
        label="Currency"
        :value="value.row.currency"
      />
    </div>
    <div class="col-3 column q-gutter-sm">
      <q-input
        v-if="value.row.operator"
        outlined
        class="col-shrink"
        readonly
        dense
        label="Changed by"
        :value="value.row.operator.username"
      />
      <q-input
        outlined
        class="col-shrink"
        readonly
        dense
        label="Payout Method Type"
        :value="value.row.payoutMethodType.name"
      />
      <q-input
        v-if="value.row.user"
        outlined
        class="col-shrink"
        readonly
        dense
        label="Operator"
        :value="value.row.user.username"
      />
      <q-input
        outlined
        class="col-shrink"
        dense
        readonly
        label="Created at"
        :value="value.row.createdAt"
      />
      <q-input
        outlined
        class="col-shrink"
        dense
        readonly
        label="Updated at"
        :value="value.row.updatedAt"
      />
    </div>
    <div
      class="col-2 column q-gutter-sm"
      v-if="Object.keys(value.row.payload).length"
    >
      <d-object-view label="Payload" :value="value.row.payload" />
    </div>
    <div
      :class="
        Object.keys(value.row.payload).length
          ? 'col-12 col-lg-3'
          : 'col-5 col-lg-5'
      "
      class="column q-gutter-sm"
      v-if="value.row.status === STATUSES.DECLINED"
    >
      <q-input
        readonly
        outlined
        :value="value.row.reasonDecline || 'None'"
        type="textarea"
        label="Comment"
      />
    </div>
  </div>
</template>

<script>
import { DObjectView } from '@/features/object-view';
import { STATUSES } from '@/shared/constants';

export default {
  components: { DObjectView },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    STATUSES() {
      return STATUSES;
    },
    username: ({ value }) => value.row?.user?.username || 'None',
  },
};
</script>
